import { useState, useEffect } from 'react';
import { getSettings } from '../../../../services/settings/actions';
import { GoogleLogin } from '@react-oauth/google';
import { LoginSocialFacebook } from 'reactjs-social-login';
import { createButton } from 'react-social-login-buttons';
import { useSelector, useDispatch } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
import { LOGIN_USER } from '../../../../services/user/actionTypes';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import './styles.scss';

const Social0Auth = (props) => {
  let settings = useSelector(
    (state) => state?.settings || state?.settings?.settings
  );
  settings = settings && 'settings' in settings ? settings.settings : settings;
  const [isSettings, setIsSettings] = useState(false);
  const isSocialGoogleEnabled = isSettings
    ? settings?.find((item) => item.key === 'enableGoogleLogin')?.value === '1'
    : false;
  const isSocialFacebookEnabled = isSettings
    ? settings?.find((item) => item.key === 'enableFacebookLogin')?.value ===
      '1'
    : false;
  const dispatch = useDispatch();
  const [authCredentials, setAuthenticationCredentials] = useState({});
  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState({
    password1: null,
    password2: null,
  });
  const [passwordError, setPasswordError] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (settings && Object.keys(settings)?.length > 0) {
      setIsSettings(true);
    } else {
      dispatch(getSettings());
    }
  }, [dispatch, settings]);

  useEffect(() => {
    if (password.password1 === '' || password.password2 === '') {
      setPasswordError(true);
    } else if (password.password1 !== password.password2) {
      setPasswordError(true);
    } else if (
      password.password1?.length < 8 ||
      password.password2?.length < 8
    ) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  }, [password]);

  const handleAuthentication = (
    provider,
    credentialResponse,
    credentialResponseDecoded
  ) => {
    const userAddress =
      typeof localStorage.getItem('address') === 'string' &&
      localStorage.getItem('userSetAddress')
        ? JSON.parse(localStorage.getItem('userSetAddress'))
        : localStorage.getItem('address');
    const authToken = !credentialResponse
      ? authCredentials?.credentialResponse
      : credentialResponse;

    const createUser = {
      provider: !provider ? authCredentials?.provider : provider,
      auth_token:
        typeof authToken === 'string' ? authToken : authToken?.credential,
      default_address: {
        address: userAddress?.formatted_address
          ? userAddress?.formatted_address
          : userAddress,
        latitude: userAddress?.geometry?.location?.lat,
        longitude: userAddress?.geometry?.location?.lng,
      },
      email: !credentialResponseDecoded
        ? authCredentials?.credentialResponseDecoded?.email
        : credentialResponseDecoded?.email,
      password:
        props.action !== 'login'
          ? password.password2
          : '0authTemporaryLogin*1979!',
      name: !credentialResponseDecoded
        ? authCredentials?.credentialResponseDecoded?.name
        : credentialResponseDecoded?.name,
      photo: !credentialResponseDecoded
        ? authCredentials?.credentialResponseDecoded?.photo
        : credentialResponseDecoded?.photo,
    };

    localStorage.setItem('user', JSON.stringify(createUser));
    dispatch({
      type: LOGIN_USER,
      payload: createUser,
    });

    // if (typeof props?.callback() === 'function') {
    console.log('CREATED-USER: ', createUser);
    props?.callback(props.type, createUser);
    // }
  };

  const config = {
    text: 'Facebook',
    icon: 'facebook',
    iconFormat: (name) => `fa fa-${name}`,
    className: 'facebook-login-btn-configs',
    style: {
      height: 40,
      width: props?.width || 400,
      justifyContent: 'center',
      margin: 0,
      background: '#3b5998',
      color: '#FFF',
      boxShadow: 'none',
      fontSize: 16,
    },
    activeStyle: {
      background: 'rgba(59, 89, 152, 0.9)',
      color: '#FFF',
    },
    align: 'center',
  };

  const FacebookLoginButton = createButton(config);

  return (
    <>
      {(!!isSocialGoogleEnabled || !!isSocialFacebookEnabled) && (
        <div className="login-or">OR</div>
      )}
      {isSocialGoogleEnabled && (
        <div className="google-login-btn">
          <GoogleLogin
            size="large"
            logo_alignment="center"
            width={props?.width || 400}
            onSuccess={(credentialResponse) => {
              const credentials = credentialResponse.credential;
              const credentialResponseDecoded = jwtDecode(credentials);
              setAuthenticationCredentials({
                provider: 'google',
                credentialResponse,
                credentialResponseDecoded,
              });
              if (props.action === 'signup') {
                handleClickOpen();
              } else {
                handleAuthentication(
                  'google',
                  credentialResponse,
                  credentialResponseDecoded
                );
              }
            }}
            onError={() => {
              console.log('Login Failed');
            }}
          />
        </div>
      )}
      {isSocialFacebookEnabled && (
        <div className="facebook-login-btn">
          <LoginSocialFacebook
            appId={process.env.REACT_APP_FB_APP_ID}
            onResolve={(response) => {
              const credentialResponse = response?.data.accessToken;
              const credentialResponseDecoded = {
                email: response?.data.email,
                name: response?.data.name,
                photo: response?.data?.picture?.data?.url,
              };
              setAuthenticationCredentials({
                provider: 'facebook',
                credentialResponse,
                credentialResponseDecoded,
              });
              if (props.action === 'signup') {
                handleClickOpen();
              } else {
                handleAuthentication(
                  'facebook',
                  credentialResponse,
                  credentialResponseDecoded
                );
              }
            }}
            onReject={(error) => console.log(error)}
          >
            <FacebookLoginButton />
          </LoginSocialFacebook>
        </div>
      )}
      {/** Password Promt Dialog */}
      {!!open && (
        <div className="social-password-promt-dialog-wrapper">
          <div
            className={`${
              props?.isMobile
                ? 'social-password-promt-dialog-content social-password-promt-dialog-content--mobile'
                : 'social-password-promt-dialog-content'
            }`}
          >
            <span
              className="social-password-promt-dialog-close"
              onClick={handleClose}
            >
              &times;
            </span>
            <Typography
              style={passwordError ? { color: 'red' } : { color: '#000' }}
              variant="h6"
            >
              Please create a password for your new account.
            </Typography>
            <TextField
              className="filled-input-white"
              style={{
                marginBottom: 10,
                marginTop: 20,
                backgroundColor: 'white',
                borderColor: 'black',
                width: '100%',
              }}
              label="Password"
              id="filled-size-small"
              defaultValue=""
              type="password"
              onChange={(e) =>
                setPassword({ ...password, password1: e.target.value })
              }
              error={passwordError}
              variant="outlined"
            />
            {!!passwordError && (
              <span className="social-password-promt-dialog-password-info">
                Password length min. 8 chars
              </span>
            )}
            <TextField
              className="filled-input-white"
              style={{
                marginBottom: 10,
                marginTop: 20,
                backgroundColor: 'white',
                borderColor: 'black',
                width: '100%',
              }}
              label="Repeat Password"
              id="filled-size-small"
              defaultValue=""
              type="password"
              onChange={(e) =>
                setPassword({ ...password, password2: e.target.value })
              }
              error={passwordError}
              variant="outlined"
            />
            <Button
              disabled={passwordError}
              onClick={() => handleAuthentication()}
              style={{
                marginTop: 30,
                width: '100%',
                backgroundColor: '#8c1ed3',
                borderRadius: '5px',
                color: 'white',
              }}
              variant="contained"
              size="large"
              color="primary"
            >
              {'SUBMIT'}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default Social0Auth;
