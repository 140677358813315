import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRestaurantAllFilters } from '../../../../services/restaurant/actions';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import StoreMallDirectoryOutlinedIcon from '@mui/icons-material/StoreMallDirectoryOutlined';
import LocalDiningOutlinedIcon from '@mui/icons-material/LocalDiningOutlined';
import FlagCircleOutlinedIcon from '@mui/icons-material/FlagCircleOutlined';
import TuneOutlined from '@mui/icons-material/TuneOutlined';
import Avatar from '@mui/material/Avatar';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: useTheme().spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function FiltersDrawer({
  callbackFilterRestaurants,
  callbackDrawerStatus,
  hasDrawerStatus,
  deliveryType,
}) {
  const localstorage = Object.assign({}, window.localStorage);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [drawerStatus, setDrawerStatus] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);

  useEffect(() => {
    dispatch(
      getRestaurantAllFilters(
        localstorage.userLat,
        localstorage.userLng,
        deliveryType
      )
    );
  }, [dispatch, localstorage.userLat, localstorage.userLng, deliveryType]);

  useEffect(() => {
    if (hasDrawerStatus !== drawerStatus) {
      setDrawerStatus(hasDrawerStatus);
    }
  }, [drawerStatus, hasDrawerStatus]);

  const filtersData = useSelector((state) => state.restaurant.filters);
  const { restaurants, cuisines, filters } = filtersData;

  const formattedCategories =
    Array.isArray(restaurants) && restaurants.length
      ? restaurants
          .reduce((acc, item) => {
            const existingCategory = acc.find(
              (cat) => cat.category_unique_name === item.category_name
            );

            if (existingCategory) {
              existingCategory.restaurantIds.add(item.restaurant_id);
            } else {
              acc.push({
                restaurantIds: new Set([item.restaurant_id]),
                category_unique_name: item.category_name,
                is_active: item.is_active,
              });
            }

            return acc;
          }, [])
          .map((category) => ({
            ...category,
            restaurantIds: Array.from(category.restaurantIds),
          }))
      : [];

  const handleCheckboxChange = (restaurantId, categoryName) => {
    setSelectedFilters((prev) => {
      const currentFilters = Array.isArray(prev) ? prev : [];

      const restaurantIndex = currentFilters.findIndex(
        (item) => item.restaurantId === restaurantId
      );

      if (restaurantIndex !== -1) {
        const restaurant = currentFilters[restaurantIndex];
        const isCategoryActive = restaurant.categories[categoryName] || false;

        const updatedCategories = {
          ...restaurant.categories,
          [categoryName]: !isCategoryActive,
        };

        const hasActiveCategories = Object.values(updatedCategories).some(
          (value) => value === true
        );

        if (hasActiveCategories) {
          return [
            ...currentFilters.slice(0, restaurantIndex),
            { restaurantId, categories: updatedCategories },
            ...currentFilters.slice(restaurantIndex + 1),
          ];
        } else {
          return currentFilters.filter((_, index) => index !== restaurantIndex);
        }
      } else {
        return [
          ...currentFilters,
          {
            restaurantId,
            categories: {
              [categoryName]: true,
            },
          },
        ];
      }
    });
  };

  useEffect(() => {
    callbackFilterRestaurants(selectedFilters);
  }, [callbackFilterRestaurants, selectedFilters]);

  return (
    <>
      <ListItem
        onClick={() => {
          setDrawerStatus(true);
          callbackDrawerStatus(true);
        }}
        button
      >
        <ListItemText primary="Filters" />
        <ListItemAvatar>
          <Avatar>
            <TuneOutlined />
          </Avatar>
        </ListItemAvatar>
      </ListItem>

      <Drawer
        PaperProps={{
          sx: { width: '30%' },
        }}
        anchor={'right'}
        open={drawerStatus}
        onClose={() => {
          setDrawerStatus(false);
          callbackDrawerStatus(false);
        }}
      >
        <div style={{ padding: 1 }}>
          <IconButton
            onClick={() => {
              setDrawerStatus(false);
              callbackDrawerStatus(false);
            }}
            aria-label="close"
            className={classes.margin}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <div className="pl-20">
          <div className="row bg-white">
            <div className="col-12">
              <div className="pl-2">
                <Typography
                  variant="h5"
                  gutterBottom
                  className="d-flex mt-3 align-items-center"
                >
                  <TuneOutlinedIcon className="mr-2 align-items-center" />
                  <b>
                    <u>Filters</u>
                  </b>
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div className="pl-20">
          <div className="row bg-white">
            <div className="col-12">
              <div className="pl-2 py-3">
                <Typography
                  variant="h6"
                  gutterBottom
                  className="d-flex mt-3 align-items-center"
                >
                  <StoreMallDirectoryOutlinedIcon className="mr-2 align-items-center" />
                  <b>Store Types</b>
                </Typography>
                <div className="row">
                  <div className="d-flex flex-wrap col-12 ml-2">
                    {formattedCategories &&
                      formattedCategories.map((category) => (
                        <FormControlLabel
                          key={category.category_unique_name}
                          control={
                            <Checkbox
                              checked={category.restaurantIds.every(
                                (restaurantId) =>
                                  selectedFilters.some(
                                    (filter) =>
                                      filter.restaurantId === restaurantId &&
                                      filter.categories[
                                        category.category_unique_name
                                      ]
                                  )
                              )}
                              onChange={() =>
                                category.restaurantIds.forEach((restaurantId) =>
                                  handleCheckboxChange(
                                    restaurantId,
                                    category.category_unique_name
                                  )
                                )
                              }
                              name={category.category_unique_name}
                            />
                          }
                          label={category.category_unique_name}
                        />
                      ))}
                  </div>
                </div>
                <hr />

                <Typography
                  variant="h6"
                  gutterBottom
                  className="d-flex mt-3 align-items-center"
                >
                  <FlagCircleOutlinedIcon className="mr-2 align-items-center" />
                  <b>Regional Dishes</b>
                </Typography>
                <div className="row">
                  <div className="d-flex flex-wrap col-12 ml-2">
                    {cuisines &&
                      cuisines.map((cuisine) => (
                        <FormControlLabel
                          key={cuisine.id}
                          control={
                            <Checkbox
                              checked={selectedFilters.some(
                                (filter) =>
                                  filter.restaurantId ===
                                    cuisine.restaurant_id &&
                                  filter.categories[cuisine.cuisine_name]
                              )}
                              onChange={() =>
                                handleCheckboxChange(
                                  cuisine.restaurant_id,
                                  cuisine.cuisine_name
                                )
                              }
                              name={cuisine.cuisine_name}
                            />
                          }
                          label={cuisine.cuisine_name}
                        />
                      ))}
                  </div>
                </div>
                <hr />
                <Typography
                  variant="h6"
                  gutterBottom
                  className="d-flex mt-3 align-items-center"
                >
                  <LocalDiningOutlinedIcon className="mr-2 align-items-center" />
                  <b>Alternative Diets</b>
                </Typography>
                <div className="row">
                  <div className="d-flex flex-wrap col-12 ml-2">
                    {filters &&
                      filters.map((filter) => (
                        <FormControlLabel
                          key={filter.id}
                          control={
                            <Checkbox
                              checked={selectedFilters.some(
                                (selFilter) =>
                                  selFilter.restaurantId ===
                                    filter.restaurant_id &&
                                  selFilter.categories[filter.filter_name]
                              )}
                              onChange={() =>
                                handleCheckboxChange(
                                  filter.restaurant_id,
                                  filter.filter_name
                                )
                              }
                              name={filter.filter_name}
                            />
                          }
                          label={filter.filter_name}
                        />
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}
