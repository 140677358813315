import React, { useState, useEffect, useCallback, useRef } from 'react';

import { useDispatch } from 'react-redux';
import {
  loadCart,
  addProduct,
  removeProduct,
} from '../../../services/cart/actions';
import { updateCart } from '../../../services/total/actions';
/*import {
  getRestaurantItems,
  getRestaurantInfo,
} from '../../../services/items/actions';*/

// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import StarIcon from '@mui/icons-material/Star';
// import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Axios from 'axios';
import {
  GET_RESTAURANT_ITEMS_URL,
  GET_RESTAURANT_INFO_URL,
  ROOT_URL,
} from '../../../configs';
import Skeleton from '@mui/material/Skeleton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Header from '../Layouts/Header';
import { useParams } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// import CheckBoxOutlined from '@mui/icons-material/CheckBoxOutlined';
// import FavouriteBorder from '@mui/icons-material/FavoriteBorder';
// import FavoriteOutlined from '@mui/icons-material/FavoriteOutlined';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import Search from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Grow from '@mui/material/Grow';
// import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DirectionsOutlinedIcon from '@mui/icons-material/DirectionsOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
// import { useHistory } from 'react-router-dom';
import ItemFiltersDrawer from './Filters/ItemFiltersDrawer.js';

export default function RestaurantDetail(params) {
  const localstorage = Object.assign({}, window.localStorage);
  const dispatch = useDispatch();
  const { resSlug } = useParams();
  // const history = useHistory();
  // const myRef = useRef(null)

  const topPicks = useRef(null);
  const myRefs = useRef([]);
  // const categories  = []
  // let refsArray  =  [];

  const [loading, setLoading] = useState(true);
  const [restaurant, setRestaurant] = useState({});
  const [recommended, setRecommended] = useState([]);
  const [arrayHolderRecommended, setArrayHolderRecommended] = useState([]);
  const [arrayHolderItems, setArrayHolderItems] = useState([]);
  const [items, setItems] = useState({});
  const [currentCat, setCurrentCat] = useState('Recommended');

  // const [scroll, setScroll] = useState(0);
  const [scrolled, setScrolled] = useState(false);
  // const [vegOnly, setVegOnly] = useState(false);
  const [cart, setCart] = useState(
    localstorage?.cart ? JSON.parse(localstorage?.cart) : []
  );
  const [search, setSearch] = useState('');
  // const [filters, setFilters] = useState([]);

  useEffect(() => {
    Axios.post(GET_RESTAURANT_INFO_URL + '/' + resSlug)
      .then((res) => {
        // console.log(res.data)
        setRestaurant(res.data);
      })
      .catch((e) => console.log(e));
  }, [resSlug]);

  useEffect(() => {
    Axios.post(GET_RESTAURANT_ITEMS_URL + '/' + resSlug)
      .then((res) => {
        console.log(res.data);
        setItems(res.data.items);
        setRecommended(res.data.recommended);
        setArrayHolderItems(res.data.items);
        setArrayHolderRecommended(res.data.recommended);
        setLoading(false);
      })
      .catch((e) => console.log(e));
  }, [resSlug]);

  const getRestaurantId = () => {
    return restaurant.id;
  };

  // useEffect(()=>{
  //   if(true){
  //     setRecommended(arrayHolderRecommended.filter(i=>i.is_veg===1))

  //     let newItems = {};

  //     Object.keys(arrayHolderItems).map((keys)=>{
  //       return newItems[keys]=arrayHolderItems[keys].filter(i=>i.is_veg===1);
  //     })

  //     setItems(newItems)

  //   }else{
  //     setRecommended(arrayHolderRecommended)
  //     setItems(arrayHolderItems)
  //   }
  // },[arrayHolderItems, arrayHolderRecommended])

  // const createCatRefs=()=>{
  //   refsArray  = Object.keys(items).map((item)=>createRef());
  //   console.log(refsArray)
  // }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return function () {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  const handleScroll = () => {
    let lastScrollY = window.scrollY;
    if (lastScrollY > 240) {
      setScrolled(true);
    }
    if (lastScrollY === 0) {
      setScrolled(false);
    }
  };

  const addToCart = (item) => {
    console.log('CART-ITEM-DESKTOP: ', item);
    if (localStorage.getItem('cartRestaurant') !== null) {
      if (
        JSON.parse(localStorage.getItem('cartRestaurant')).id !== restaurant.id
      ) {
        localStorage.removeItem('cart');
        localStorage.removeItem('cartRestaurant');
        setCart([{ ...item, quantity: 1, cartPrice: item.price }]);
        localStorage.setItem('cartRestaurant', JSON.stringify(restaurant));
        localStorage.setItem(
          'cart',
          JSON.stringify([{ ...item, quantity: 1, cartPrice: item.price }])
        );
        return;
      }
    }
    setCart([...cart, { ...item, quantity: 1, cartPrice: item.price }]);
    localStorage.setItem('cartRestaurant', JSON.stringify(restaurant));
    localStorage.setItem(
      'cart',
      JSON.stringify([...cart, { ...item, quantity: 1, cartPrice: item.price }])
    );
  };

  const incrementCart = (citem) => {
    console.log(citem);
    let item = cart.filter((i) => i.id === citem.id)[0];
    console.log(item);
    let newItems = cart.filter((i) => i.id !== citem.id);
    item.quantity = item.quantity + 1;
    item.cartPrice = parseFloat(item.price) * item.quantity;
    newItems.push(item);
    // newItems = newItems.filter((i) => i.id === citem.id).push(item);
    setCart(newItems);
    localStorage.setItem('cart', JSON.stringify(newItems));
  };

  const decrementCart = (citem) => {
    console.log(citem);
    let item = cart.filter((i) => i.id === citem.id)[0];
    if (item.quantity === 1) {
      let newItems = cart.filter((i) => i.id !== citem.id);
      item.cartPrice = item.cartPrice - parseFloat(item.price);
      setCart(newItems);
      localStorage.setItem('cart', JSON.stringify(newItems));
      if (newItems.length === 0) {
        localStorage.removeItem('cart');
      }
    } else {
      let newItems = cart.filter((i) => i.id !== citem.id);
      item.quantity = item.quantity - 1;
      item.cartPrice = parseFloat(item.price) * item.quantity;
      newItems.push(item);
      setCart(newItems);
      localStorage.setItem('cart', JSON.stringify(newItems));
    }
    console.log(item);
    removeProduct(item);
  };

  // const removeFromCart=(item)=>{
  //   let newItems = cart.filter((i)=>i.id !== item.id)
  //   setCart([newItems])
  // }

  /*function subTotal() {
    let total = 0;
    cart.map((i) => (total += parseInt(i.cartPrice)));
    console.log(total);
    return String(total).replace(/(\d)(?=(\d\d)+\d$)/g, '$1,');
  }*/

  const searchItems = (text) => {
    setSearch(text);

    let newData = arrayHolderRecommended.filter((item) => {
      let itemData = `${String(item.name).toUpperCase()}`;
      let textData = text.toUpperCase();
      return itemData.indexOf(textData) > -1;
    });
    setRecommended(newData);

    let newItems = {};
    Object.keys(arrayHolderItems).map((keys) => {
      let newData2 = (newItems[keys] = arrayHolderItems[keys].filter((item) => {
        let itemData = `${String(item.name).toUpperCase()}`;
        let textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      }));
      return (newItems[keys] = newData2);
    });
    setItems(newItems);
  };

  const copyText = () => {
    navigator.clipboard.writeText(restaurant.address);
    alert('Address Copied.');
  };

  const filterItems = (v) => {
    console.log('FILER: ', v);
    // setFilters(v);

    if (v.length === 0) {
      setItems(arrayHolderItems);
      setRecommended(arrayHolderRecommended);

      return;
    }

    let f = [];
    let newData1 = [];
    let newData = [];

    newData1 = arrayHolderRecommended.filter((i) => {
      if (i.placeholder_image) {
        f = i.placeholder_image.split(',');

        if (hasDuplicates(v, f)) {
          return i;
        }
      }
      return i;
    });

    Object.keys(arrayHolderItems).map((keys) => {
      newData = arrayHolderItems[keys].filter((i) => {
        if (i.placeholder_image) {
          f = i.placeholder_image.split(',');

          if (hasDuplicates(v, f)) {
            return i;
          }
        }
        return i;
      });
      return keys;
    });

    setRecommended(newData1);
    setItems(newData);
  };

  function hasDuplicates(arr1, arr2) {
    // console.log(new Set(arr))
    // console.log(arr.length)
    // return new Set(arr).size !== arr.length;
    var duplicates = arr1.filter(function (val) {
      return arr2.indexOf(val) !== -1;
    });
    if (duplicates.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  const refreshItemList = useCallback(
    () => setCart(localstorage?.cart ? JSON.parse(localstorage?.cart) : []),
    [localstorage?.cart]
  );

  useEffect(() => refreshItemList(), [refreshItemList]);

  useEffect(() => {
    // dispatch cart products to store for mobile display
    dispatch(addProduct(...cart));
    dispatch(loadCart([...cart]));
    dispatch(updateCart([...cart]));
  }, [cart, dispatch]);

  console.log(restaurant);

  return (
    <div className="container-fluid p-0">
      <Header getFixed={scrolled} callback={refreshItemList} />

      <div
        className="row"
        style={{
          transition: 'all 0.5s cubic-bezier(0.4, 0, 1, 1) 0s',
          position: 'sticky',
          top: 0,
          marginTop: 64,
          paddingTop: 15,
          boxShadow:
            '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
          backgroundImage:
            'linear-gradient(to right, rgb(196, 25, 175), rgb(114, 32, 227))',
          zIndex: 0,
        }}
      >
        <div className="restaurant-detail-image-wrapper">
          <img
            alt="logo"
            // src={ROOT_URL + "/public" + restaurant.image}
            src={ROOT_URL + restaurant.image}
          />
        </div>
        <div className="col-md-7">
          <div style={{ paddingBottom: '10px', color: 'white' }}>
            <Typography style={{ color: 'white' }} variant={'h3'}>
              {restaurant.name}
            </Typography>
            <Typography variant="body2" style={{ padding: '10px 0' }}>
              {restaurant.description}
            </Typography>
            <Typography variant="body2">{restaurant.address}</Typography>

            <div className="row" style={{ marginTop: 28 }}>
              <div className="col-md-7">
                <div className="row">
                  <div className="col-md-12">
                    <TextField
                      fullWidth
                      id="restaurant-detail-input"
                      variant="standard"
                      //defaultValue="How can we help"
                      color="primary"
                      value={search}
                      placeholder="Search for dishes..."
                      onChange={(e) => searchItems(e.target.value)}
                      InputProps={{
                        style: {
                          color: '#fff',
                          borderColor: '#fff',
                          borderWidth: 1,
                          borderBottom: '1px solid rgba(255, 255, 255, 0.7)',
                        },
                        appearance: 'textfield',
                        endAdornment: (
                          <IconButton>
                            <Search style={{ color: '#fff' }} />
                          </IconButton>
                        ),
                      }}
                    />
                  </div>
                  <div
                    className="col-md-12 mt-3"
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      flex: 1,
                      paddingLeft: '13px',
                      rowGap: 10,
                    }}
                  >
                    <Button
                      style={{
                        color: localstorage.storeColor || '#000',
                        backgroundColor: 'white',
                        borderRadius: 3,
                      }}
                      variant="contained"
                      color="secondary"
                      size="small"
                      className="ml-1 mr-1"
                      onClick={() => copyText()}
                      startIcon={<ContentCopyIcon />}
                    >
                      Copy Address
                    </Button>
                    <Button
                      style={{
                        backgroundColor: 'white',
                        borderRadius: 3,
                        color: localstorage.storeColor || '#000',
                      }}
                      variant="contained"
                      color="secondary"
                      size="small"
                      className="ml-1 mr-1"
                      onClick={() =>
                        (window.location = `http://maps.google.com/maps?saddr=${
                          restaurant.latitude
                        },${restaurant.longitude}&daddr=${JSON.parse(
                          localStorage.getItem('userLat')
                        )},${JSON.parse(localStorage.getItem('userLng'))}`)
                      }
                      startIcon={<DirectionsOutlinedIcon />}
                    >
                      Direction
                    </Button>
                    <Button
                      style={{
                        backgroundColor: 'white',
                        borderRadius: 3,
                        color: localstorage.storeColor || '#000',
                      }}
                      variant="contained"
                      color="secondary"
                      size="small"
                      className="ml-1 mr-1"
                      onClick={() => {
                        window.location = `tel:${restaurant.contact_number}`;
                      }}
                      startIcon={<CallOutlinedIcon />}
                    >
                      Call
                    </Button>

                    <ItemFiltersDrawer
                      restaurantId={getRestaurantId()}
                      filterItems={(v) => filterItems(v)}
                    />
                  </div>
                </div>
              </div>

              <div className="restaurant-detail-header-property">
                <div>
                  <Typography style={{ color: 'white' }} variant="h6">
                    <StarIcon className="restaurant-detail-header-ratings-icon" />
                    {restaurant.rating}
                  </Typography>
                  <Typography style={{ color: '#ffffffba' }} variant="h8">
                    Ratings
                  </Typography>
                </div>
              </div>
              <div className="restaurant-detail-header-property">
                <div>
                  <Typography
                    className={`restaurant-detail-header-item-pricing-alignment--${localStorage.getItem(
                      'currencySymbolAlign'
                    )}`}
                    style={{ color: 'white' }}
                    variant="h6"
                    component="h6"
                  >
                    <div
                      className={`${localStorage.getItem(
                        'currencySymbolAlign'
                      )}`}
                    >
                      {localStorage.getItem('currencyFormat')}{' '}
                    </div>
                    <div>{restaurant.price_range}</div>
                  </Typography>
                  <Typography style={{ color: '#ffffffba' }} variant="h8">
                    Price Range
                  </Typography>
                </div>
              </div>
              <div className="restaurant-detail-header-property">
                <div>
                  <Typography style={{ color: 'white' }} variant="h6">
                    {restaurant.msg}
                  </Typography>
                  <Typography style={{ color: '#ffffffba' }} variant="h8">
                    Delivery Time
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3"></div>
      </div>

      <div className="restaurant-items-wrapper container-fluid">
        <div
          className="col-md-3"
          style={{
            borderRight: '1px solid rgba(177, 170, 170, 0.3)',
            minHeight: 540,
            position: 'sticky',
            top: 138,
            height: 'calc(100vh - 197px)',
            marginTop: '10px',
          }}
        >
          <div style={{ margin: 10, marginTop: 0, position: 'sticky', top: 0 }}>
            {!loading && (
              <List component="nav" aria-label="main mailbox folders">
                {!!recommended?.length && (
                  <ListItem
                    style={{
                      textAlign: 'right',
                      background:
                        currentCat === 'Recommended' ? '#7420e291' : '#fff',
                      borderRadius: 5,
                    }}
                    onClick={() => {
                      setCurrentCat('Recommended');
                      topPicks.current.scrollIntoView();
                    }}
                    button
                  >
                    <ListItemText
                      style={{ fontWeight: 'bold' }}
                      primary="Recommended"
                    />
                  </ListItem>
                )}
                {Object.keys(items).map((item) => {
                  return (
                    <ListItem
                      style={{
                        textAlign: 'right',
                        background: currentCat === item ? '#7420e291' : '#fff',
                      }}
                      onClick={() => {
                        setCurrentCat(item);
                        myRefs.current[item].scrollIntoView();
                      }}
                      button
                    >
                      <ListItemText
                        style={{ fontWeight: 'bold' }}
                        primary={item}
                      />
                    </ListItem>
                  );
                })}
              </List>
            )}
          </div>
        </div>

        <div className="w-100">
          <div style={{ padding: 25 }}>
            {!!recommended?.length && (
              <h4 ref={topPicks} className={'mb-3'}>
                <b>Recommended</b>
              </h4>
            )}

            {loading && (
              <div className="row" key={'g-c'}>
                {recommended?.map((i) => {
                  return (
                    <div className="col-md-6" key={`${i}-c`}>
                      <div className="mb-3" key={`${i}-g`}>
                        <Skeleton variant="rect" height={150} key={`${i}-h`} />
                        <Skeleton
                          variant="text"
                          style={{ marginTop: 5 }}
                          key={`${i}-i`}
                        />
                        <Skeleton variant="text" width={100} key={`${i}-r`} />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            {recommended?.length > 0 && (
              <div className="row" key={`${recommended?.length}-l`}>
                {/*eslint-disable-next-line*/}
                {recommended?.map((item) => {
                  return (
                    <Grow in={true} key={`${item}-c`}>
                      <div key={`${item.id}-d`} className="col-md-12">
                        <div
                          key={`${item.id}-w`}
                          className={
                            'p-3 dashed-bottom ' +
                            (restaurant.is_open === 0 ? 'page-inactive' : '')
                          }
                        >
                          <div className="row" key={`${item.id}-k`}>
                            <div className="col-md-8">
                              <CardActionArea>
                                <CardContent>
                                  {item.is_veg === 1 && (
                                    <img
                                      alt="..."
                                      src="/assets/img/various/veg-icon.png"
                                      style={{ width: 21, height: 21 }}
                                    />
                                  )}
                                  {item.is_veg === 0 && (
                                    <img
                                      alt="..."
                                      src="/assets/img/various/non-veg-icon.png"
                                      style={{ width: 21, height: 21 }}
                                    />
                                  )}
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="h2"
                                  >
                                    {item.name}
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    component="div"
                                  >
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: item.desc,
                                      }}
                                    />
                                  </Typography>
                                  <Typography
                                    className={`restaurant-item-pricing-alignment--${localStorage.getItem(
                                      'currencySymbolAlign'
                                    )}`}
                                    variant="h6"
                                    component="h6"
                                  >
                                    <div
                                      className={`${localStorage.getItem(
                                        'currencySymbolAlign'
                                      )}`}
                                    >
                                      {localStorage.getItem('currencyFormat')}{' '}
                                    </div>
                                    <div>{item.price}</div>
                                  </Typography>
                                </CardContent>
                              </CardActionArea>
                            </div>
                            <div className="col-md-4">
                              <img
                                // src={ROOT_URL + "/public/" + item.image}
                                src={ROOT_URL + item.image}
                                alt="HotpotAfrica"
                                style={{
                                  width: '100%',
                                  borderRadius: 8,
                                  objectFit: 'cover',
                                }}
                              />

                              {cart.some((i) => i.id === item.id) ? (
                                <div
                                  className="MuiButton-contained"
                                  style={{
                                    position: 'relative',
                                    top: -5,
                                    width: '100%',
                                    height: 40,
                                    marginLeft: 0,
                                    background: 'white',
                                    color: 'green',
                                    fontWeight: 'bold',
                                    border: '1px',
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    borderBottomLeftRadius: 8,
                                    borderBottomRightRadius: 8,
                                    padding: 3,
                                    boxShadow:
                                      '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignContent: 'flex-start',
                                      justifyContent: 'flex-start',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <IconButton
                                      onClick={() => decrementCart(item)}
                                      size="small"
                                      aria-label="delete"
                                    >
                                      <RemoveIcon />
                                    </IconButton>
                                    <p
                                      style={{
                                        paddingLeft: 7,
                                        paddingRight: 7,
                                        display: 'inline-block',
                                        marginBottom: 0,
                                      }}
                                    >
                                      {
                                        cart.filter((a) => a.id === item.id)[0]
                                          .quantity
                                      }
                                    </p>
                                    <IconButton
                                      onClick={() => incrementCart(item)}
                                      size="small"
                                      aria-label="plus"
                                    >
                                      <AddIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              ) : (
                                <Button
                                  onClick={() => addToCart(item)}
                                  style={{
                                    top: -5,
                                    width: '100%',
                                    height: 40,
                                    marginLeft: 0,
                                    background: 'white',
                                    color: 'green',
                                    fontWeight: 'bold',
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    borderBottomLeftRadius: 8,
                                    borderBottomRightRadius: 8,
                                    cursor: 'pointer',
                                  }}
                                  variant="contained"
                                >
                                  ADD
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grow>
                  );
                })}
              </div>
            )}

            <div className="row">
              {Object.entries(items).map(([keys, values]) => {
                return (
                  <>
                    {keys.length !== 0 ? (
                      <>
                        <h4
                          ref={(el) => (myRefs.current[keys] = el)}
                          style={{
                            margin: '0 20px',
                            paddingBottom: '10px',
                            width: '62.5%',
                            borderBottom: '1px solid rgb(177 170 170 / 30%)',
                          }}
                        >
                          <b>{keys}</b>
                        </h4>
                        {values.map((i) => {
                          return (
                            <div key={i.id} className="col-md-12">
                              <div
                                className={
                                  'p-3 dashed-bottom ' +
                                  (restaurant.is_open === 0
                                    ? 'page-inactive'
                                    : '')
                                }
                              >
                                <div className="row">
                                  <div className="col-md-8 list-card-content">
                                    <CardActionArea>
                                      <CardContent>
                                        {i.is_veg === 1 && (
                                          <img
                                            alt="..."
                                            src="/assets/img/various/veg-icon.png"
                                            style={{ width: 21, height: 21 }}
                                          />
                                        )}
                                        {i.is_veg === 0 && (
                                          <img
                                            alt="..."
                                            src="/assets/img/various/non-veg-icon.png"
                                            style={{ width: 21, height: 21 }}
                                          />
                                        )}
                                        <Typography
                                          gutterBottom
                                          variant="h5"
                                          component="h2"
                                        >
                                          {i.name}
                                        </Typography>
                                        <Typography
                                          variant="body1"
                                          color="textSecondary"
                                          component="p"
                                        >
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: i.desc,
                                            }}
                                          />
                                        </Typography>
                                      </CardContent>
                                    </CardActionArea>
                                    <Typography
                                      className={`restaurant-item-pricing-alignment--${localStorage.getItem(
                                        'currencySymbolAlign'
                                      )}`}
                                      variant="h6"
                                      color="textSecondary"
                                      component="h6"
                                    >
                                      <div
                                        className={`${localStorage.getItem(
                                          'currencySymbolAlign'
                                        )}`}
                                      >
                                        {localStorage.getItem('currencyFormat')}{' '}
                                      </div>
                                      <div>{i.price}</div>
                                    </Typography>
                                  </div>
                                  <div className="col-md-4">
                                    <img
                                      // src={ROOT_URL + "/public/" + i.image}
                                      src={ROOT_URL + i.image}
                                      alt="HotpotAfrica"
                                      style={{ width: '100%', borderRadius: 5 }}
                                    />
                                    {cart.some((g) => g.id === i.id) ? (
                                      <div
                                        className="MuiButton-contained"
                                        style={{
                                          position: 'relative',
                                          top: -5,
                                          width: '100%',
                                          height: 40,
                                          marginLeft: 0,
                                          background: 'white',
                                          color: 'green',
                                          fontWeight: 'bold',
                                          border: '1px',
                                          borderTopLeftRadius: 0,
                                          borderTopRightRadius: 0,
                                          borderBottomLeftRadius: 8,
                                          borderBottomRightRadius: 8,
                                          padding: 3,
                                          boxShadow:
                                            '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                            alignContent: 'flex-start',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <IconButton
                                            onClick={() => decrementCart(i)}
                                            size="small"
                                            aria-label="delete"
                                          >
                                            <RemoveIcon />
                                          </IconButton>
                                          <p
                                            style={{
                                              paddingLeft: 7,
                                              paddingRight: 7,
                                              display: 'inline-block',
                                              marginBottom: 0,
                                            }}
                                          >
                                            {
                                              cart.filter(
                                                (a) => a.id === i.id
                                              )[0].quantity
                                            }
                                          </p>
                                          <IconButton
                                            onClick={() => incrementCart(i)}
                                            size="small"
                                            aria-label="plus"
                                          >
                                            <AddIcon />
                                          </IconButton>
                                        </div>
                                      </div>
                                    ) : (
                                      <Button
                                        onClick={() => addToCart(i)}
                                        style={{
                                          top: -5,
                                          width: '100%',
                                          height: 40,
                                          marginLeft: 0,
                                          background: 'white',
                                          color: 'green',
                                          fontWeight: 'bold',
                                          borderTopLeftRadius: 0,
                                          borderTopRightRadius: 0,
                                          borderBottomLeftRadius: 8,
                                          borderBottomRightRadius: 8,
                                          cursor: 'pointer',
                                        }}
                                        variant="contained"
                                      >
                                        ADD
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      ''
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {!loading && restaurant.length === 0 && (
        <div className="row">
          <div className="col-md-12">
            <div style={{ padding: 50, textAlign: 'center' }}>
              <img
                alt="slider"
                style={{ width: 345 }}
                src="https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/connection_error_bsppck"
              />
              <h4 className={'mb-2'}>
                Oh! All restaurants are <br /> currently unserviceable
              </h4>
              <p className={'text-muted'}>
                All restaurants are unserviceable, check back in some time.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
