import React, { useState, useEffect, useCallback, useContext } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { ContextStore } from '../../../configs/lib/ReactiveStatusProvider';
import { logoutUser } from '../../../services/user/actions';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { KeyboardArrowDown } from '@mui/icons-material/';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PermIdentitySharpIcon from '@mui/icons-material/PermIdentitySharp';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import StorefrontSharpIcon from '@mui/icons-material/StorefrontSharp';
import HandleGooglePlacesAutocomplete from '../../Shared/HandleGooglePlacesAutocomplete';
import { useHistory, useLocation } from 'react-router-dom';
import LoginDrawer from '../Auth/LoginDrawer';
import SignupDrawer from '../Auth/SignupDrawer';
import CartIcon from '../../Shared/CartIcon';
import { useOnlineStatus } from '../../../configs/lib/OnlineStatusProvider';
import OffLine from '../../Shared/Pages/OffLine';
import './styles.scss';
import '../styles.scss';

export default function Header({ getFixed, callback }) {
  const { loggedIn, loggedInState, changedAddress, changedAddressState } =
    useContext(ContextStore);
  const isOnline = useOnlineStatus();
  const dispatch = useDispatch();
  const localstorage = Object.assign({}, window.localStorage);
  const user = useSelector((state) => state?.user);
  const history = useHistory();
  const location = useLocation();

  const [locationDrawer, setLocationDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  let open = Boolean(anchorEl);

  const [login, showLogin] = useState(false);
  const [registry, showRegistry] = useState(false);

  const [isLoading, setLoading] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (type) => {
    setAnchorEl(null);
    if (type === 'login') {
      showRegistry(false);
      showLogin(true);
    } else if (type === 'registry') {
      showLogin(false);
      showRegistry(true);
    } else if (type === 'logout') {
      logout();
    }
  };

  const callbackHandleSelect = () => {
    history.go(0);
  };

  const triggerCallback = (reopen) => {
    showLogin(false);
    showRegistry(false);

    if (reopen && reopen === 'login') {
      showLogin(true);
    } else if (reopen && reopen === 'registry') {
      showRegistry(true);
    }
  };

  const getInitials = (string) => {
    if (!string) {
      return '--';
    }
    var names = string?.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  // Logout user from front -and server side
  const logout = () => {
    if (loggedIn) {
      loggedInState(false);
    }
    Object.keys(user.user).length > 0 && dispatch(logoutUser(user.user));
    localStorage.removeItem('user');
    history.push('/');
  };

  const handleGooglePlacesAutocompleteLoading = (loading) => {
    setLoading(loading);
  };

  const handleAddress = useCallback(() => {
    changedAddressState(false);
    return localStorage.getItem('address');
  }, [changedAddressState]);

  const hexToRgb = (hex) => {
    if (!hex) return '';
    const result =
      'rgba(' +
      [
        ('0x' + hex[1] + hex[2]) | 0,
        ('0x' + hex[3] + hex[4]) | 0,
        ('0x' + hex[5] + hex[6]) | 0,
      ].join() +
      ', 1)';
    return result;
  };

  useEffect(() => {
    handleAddress();
    document.documentElement.style.setProperty(
      '--storeColor',
      hexToRgb(localStorage.getItem('storeColor'))
    );
  }, [handleAddress]);

  useEffect(() => {
    handleAddress();
  }, [changedAddress, handleAddress]);

  return (
    <>
      {!isOnline && <OffLine />}
      {isLoading && (
        <div className="height-100 overlay-loading ongoing-payment-spin">
          <div className="spin-load spin-load--desktop"></div>
        </div>
      )}
      <div
        className={`${getFixed ? 'row get-fixed' : 'row'}`}
        style={{
          position: 'absolute',
          top: 0,
          width: '100%',
          margin: 0,
          zIndex: 1,
        }}
      >
        <div style={{ width: '100%', margin: 0 }}>
          <div>
            <AppBar
              className="header-app-bar"
              key={'appbar'}
              style={{
                backgroundImage: 'linear-gradient(to right, #C419AF, #7220E3)',
              }}
              position="static"
            >
              <Toolbar>
                {/*<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                          <Menu />
                        </IconButton>*/}
                <img
                  //onClick={() => history.push("/desktop/restaurants")}
                  onClick={() => history.push('/')}
                  style={{ height: '3.5rem', cursor: 'pointer' }}
                  src="/assets/img/logo_w.png"
                  alt="HotpotAfrica"
                  className="footer-logo mr-2"
                />

                <Typography
                  onClick={() => setLocationDrawer(true)}
                  //onClick={() => history.push("/")}
                  variant="h8"
                  style={{
                    display: 'flex',
                    color: 'white',
                    marginLeft: '24px',
                    cursor: 'pointer',
                  }}
                >
                  {' '}
                  {localStorage.getItem('address')} <KeyboardArrowDown />
                </Typography>
                <div className="desktop-navigation-buttons">
                  <Button
                    onClick={() => history.push('/desktop/restaurants')}
                    style={{
                      padding: 20,
                      color:
                        location.pathname === '/desktop/restaurants'
                          ? '#ffd9fa'
                          : 'white',
                    }}
                  >
                    <StorefrontSharpIcon
                      fontSize="large"
                      style={{ color: '#fff' }}
                    />
                  </Button>
                  <Button
                    onClick={() => history.push('/desktop/offers')}
                    style={{
                      padding: 20,
                      color:
                        location.pathname === '/desktop/offers'
                          ? '#ffd9fa'
                          : 'white',
                    }}
                  >
                    <LocalOfferOutlinedIcon
                      fontSize="large"
                      style={{ color: '#fff' }}
                    />
                  </Button>
                  <Button
                    onClick={() => history.push('/desktop/help')}
                    style={{
                      padding: 20,
                      color:
                        location.pathname === '/desktop/help'
                          ? '#ffd9fa'
                          : 'white',
                    }}
                  >
                    <InfoOutlinedIcon
                      fontSize="large"
                      style={{ color: '#fff' }}
                    />
                  </Button>
                  <CartIcon place="Header" />
                  <div
                    type="button"
                    className="desktop-header-nav-btns desktop-header-nav-btn"
                    style={{
                      float: 'right',
                      marginLeft: 10,
                    }}
                    onClick={() =>
                      user.loggedIn && history.push('/desktop/account')
                    }
                  >
                    {location.pathname === '/' ? (
                      localstorage?.accountMyAccount
                    ) : (
                      <>
                        {user.loggedIn ? (
                          <div className="header-my-account-icon">
                            {user?.user?.photo ? (
                              <img
                                src={user?.user?.photo}
                                className="header-my-account-icon-img"
                                alt="profile"
                              />
                            ) : (
                              <span className="header-my-account-icon-initials">
                                {getInitials(
                                  user?.user?.name || user?.user?.data?.name
                                )}
                              </span>
                            )}
                          </div>
                        ) : (
                          <PermIdentitySharpIcon
                            fontSize="large"
                            style={{ color: '#fff' }}
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                          />
                        )}
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          {!user.loggedIn && (
                            <MenuItem onClick={() => handleClose('login')}>
                              Login
                            </MenuItem>
                          )}
                          {!user.loggedIn && (
                            <MenuItem onClick={() => handleClose('registry')}>
                              Registry
                            </MenuItem>
                          )}
                          {user.loggedIn && (
                            <MenuItem onClick={() => handleClose('logout')}>
                              Logout
                            </MenuItem>
                          )}
                        </Menu>
                      </>
                    )}
                  </div>
                  {registry && (
                    <SignupDrawer
                      trigger={true}
                      triggerCallback={triggerCallback}
                      signin={localstorage?.firstScreenRegisterBtn}
                      account={localstorage?.accountMyAccount}
                    />
                  )}
                  {login && (
                    <LoginDrawer
                      trigger={true}
                      triggerCallback={triggerCallback}
                      login={localstorage?.firstScreenLoginBtn}
                    />
                  )}
                </div>
              </Toolbar>
            </AppBar>
          </div>
        </div>

        <Drawer
          anchor={'top'}
          open={locationDrawer}
          onClose={() => setLocationDrawer(false)}
        >
          <div className="header-location-drawer--desktop">
            <Typography variant="h5" gutterBottom>
              {localstorage.relocateYourSelf}
            </Typography>
            <IconButton
              onClick={() => setLocationDrawer(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>

          <div style={{ padding: 15 }}>
            <div style={{ margin: '0 30' }}>
              <HandleGooglePlacesAutocomplete
                device="desktop"
                disableReversLookup={true}
                user={user.user}
                callbackHandleSelect={callbackHandleSelect}
                loading={handleGooglePlacesAutocompleteLoading}
              />
            </div>
          </div>
        </Drawer>
      </div>
    </>
  );
}
