import {
  CHECK_RESTAURANT_OPERATION_SERVICE,
  GET_RESTAURANTS_BASED_ON_CATEGORY,
  GET_RESTAURANTS_CATEGORIES,
  GET_DELIVERY_RESTAURANTS,
  GET_SELFPICKUP_RESTAURANTS,
  GET_RESTAURANT_FILTERS,
  GET_RESTAURANT_FILTERS_BY_ID,
} from './actionTypes';

const initialState = {
  is_operational: '',
  filtered_restaurants: [],
  restaurants_categories: [],
  delivery_restaurants: [],
  selfpickup_restaurants: [],
  filters: [],
  filtersById: [],
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case CHECK_RESTAURANT_OPERATION_SERVICE:
      return { ...state, is_operational: action.payload };
    case GET_RESTAURANTS_BASED_ON_CATEGORY:
      return { ...state, filtered_restaurants: action.payload };
    case GET_RESTAURANTS_CATEGORIES:
      return { ...state, restaurants_categories: action.payload };
    case GET_DELIVERY_RESTAURANTS:
      return { ...state, delivery_restaurants: action.payload };
    case GET_SELFPICKUP_RESTAURANTS:
      return { ...state, selfpickup_restaurants: action.payload };
    case GET_RESTAURANT_FILTERS:
      return { ...state, filters: action.payload };
    case GET_RESTAURANT_FILTERS_BY_ID:
      return { ...state, filtersById: action.payload };
    default:
      return state;
  }
}
